import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  paper: {
    width: 550,
    marginBottom: 50,
    boxSizing: 'border-box',
    paddingLeft: 15,
    paddingRight: 15,
  },

  phoneImage: {
    width: '50%',
  },

  flash: {
    backgroundColor: 'rgba(49, 180, 142, 0.93)',
    background: 'linear-gradient(to top, rgba(49, 180, 142, 0.93) 0%, rgba(50, 185, 146, 0.93) 100%)',
    borderTop: [1, 'solid', theme.palette.oceanGreen],
    borderBottom: [1, 'solid', theme.palette.eucalyptus],
    boxShadow: [
      ['inset', 0, 1, 0, theme.palette.shamrock],
      [0, 1, 7, 'rgba(39, 146, 115, 0.7)'],
    ],
    textShadow: [0, -1, 0, theme.palette.lochinvar],
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    zIndex: 99,
    width: '100%',
    fontSize: 15,
    height: 52,
    color: theme.palette.white,
  },

  flashMessage: {
    margin: 0,
    width: '100%',
    textAlign: 'center',
    fontFamily: ['HelveticaNeueBold', 'HelveticaNeue', 'Helvetica', 'Arial', 'sans-serif'],
  },
}));
