import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 250,
    margin: 'auto',
  },

  content: {
    height: 435,
    width: 250,
    overflow: 'hidden',
    position: 'absolute',
  },

  menuWrapper: {
    position: 'absolute',
    top: 244,
    left: 14,
    width: 218,
    zIndex: 3,
  },

  menu: {
    minHeight: 60,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
  },

  name: {
    position: 'absolute',
    top: 46,
    left: 48,
    width: 150,
    height: 24,
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(10),
    letterSpacing: theme.typography.pxToRem(0.18),
    fontWeight: 500,
    textAlign: 'center',
    zIndex: 3,
  },

  coverWrapper: {
    zIndex: 1,
    position: 'absolute',
    top: 79,
    left: 14,
    width: 218,
    height: 170,
    overflow: 'hidden',
  },

  coverEmpty: {
    width: '100%',
    height: '100%',
    color: theme.palette.silverChalice,
    backgroundColor: theme.palette.alto,
    letterSpacing: theme.typography.pxToRem(0.4),
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
  },

  coverImage: {
    width: '100%',
  },

  image: {
    position: 'relative',
    zIndex: 2,
  },

  requestButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: -15,
    marginBottom: 10,
  },

  requestButton: {
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(8),
    padding: [[0, 5]],
    height: 30,
    width: 85,
    fontWeight: 'bold',
    lineHeight: theme.typography.pxToRem(32),
  },

  insuranceButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },

  insuranceButton: {
    textAlign: 'center',
    width: 205,
    borderRadius: 20,
    height: 30,
    fontSize: theme.typography.pxToRem(8),
    fontWeight: 'bold',
    lineHeight: theme.typography.pxToRem(32),
    boxShadow: '0px 1px 4px rgba(41, 51, 61, 0.16)',
  },

  linkPlace: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.silverChalice,
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(0.4),
  },
}));
