import gotoPremiumFinance from 'assets/images/social_pages/goto_premium_finance.png';
import twitterX from 'assets/images/social_pages/twitter_x.png';
import vcheck from 'assets/images/social_pages/vcheck.png';
import epay from 'assets/images/social_pages/epay.png';
import autoQuoteForest from 'assets/images/social_pages/auto_quote_forest.png';
import instantAutoQuoteForst from 'assets/images/social_pages/instant_auto_quote_forest.png';
import imperialPfs from 'assets/images/social_pages/imperial_pfs.png';
import simplyeasier from 'assets/images/social_pages/simplyeasier.png';
import autoQuote from 'assets/images/social_pages/auto_quote.png';
import flood from 'assets/images/social_pages/flood.png';
import instantLifeCoverege from 'assets/images/social_pages/instant_life_coverege.png';
import building from 'assets/images/social_pages/building.png';
import instantAutoQuote from 'assets/images/social_pages/instant_auto_quote.png';
import loans from 'assets/images/social_pages/loans.png';
import businessPayment from 'assets/images/social_pages/business_payment.png';
import schedulerRed from 'assets/images/social_pages/scheduler_red.png';
import schedulerSienna from 'assets/images/social_pages/scheduler_sienna.png';
import schedulerYellow from 'assets/images/social_pages/scheduler_yellow.png';
import schedulerGreen from 'assets/images/social_pages/scheduler_green.png';
import schedulerBlue from 'assets/images/social_pages/scheduler_blue.png';
import agWorkersPortal from 'assets/images/social_pages/ag_workers_portal.png';
import superiorCu from 'assets/images/social_pages/superior_cu.png';
import paTags from 'assets/images/social_pages/pa_tags.png';
import service from 'assets/images/social_pages/service.png';
import instantQuote from 'assets/images/social_pages/instant_quote.png';
import callAppt from 'assets/images/social_pages/call_appt.png';
import freeGiftTwo from 'assets/images/social_pages/free_gift_two.png';
import collegeFunding from 'assets/images/social_pages/college_funding.png';
import noDebt from 'assets/images/social_pages/no_debt.png';
import instantHomeQuote from 'assets/images/social_pages/instant_home_quote.png';
import pets from 'assets/images/social_pages/pets.png';
import agriTractor from 'assets/images/social_pages/agri_tractor.png';
import cyberInsIcon from 'assets/images/social_pages/cyber_ins_icon.png';
import cyberInsV2 from 'assets/images/social_pages/cyber_ins_v2.png';
import quickTips from 'assets/images/social_pages/quick_tips.png';
import video from 'assets/images/social_pages/video.png';
import behance from 'assets/images/social_pages/behance.png';
import paypal from 'assets/images/social_pages/paypal.png';
import ePay from 'assets/images/social_pages/e_pay.png';
import blogger from 'assets/images/social_pages/blogger.png';
import blog from 'assets/images/social_pages/blog.png';
import deviantart from 'assets/images/social_pages/deviantart.png';
import dribbble from 'assets/images/social_pages/dribbble.png';
import envato from 'assets/images/social_pages/envato.png';
import evernote from 'assets/images/social_pages/evernote.png';
import fbReview from 'assets/images/social_pages/fb_review.png';
import facebook from 'assets/images/social_pages/facebook.png';
import flickr from 'assets/images/social_pages/flickr.png';
import googleplus from 'assets/images/social_pages/googleplus.png';
import instagram from 'assets/images/social_pages/instagram.png';
import linkedin from 'assets/images/social_pages/linkedin.png';
import messenger from 'assets/images/social_pages/messenger.png';
import pinterest from 'assets/images/social_pages/pinterest.png';
import rss from 'assets/images/social_pages/rss.png';
import skype from 'assets/images/social_pages/skype.png';
import soundcloud from 'assets/images/social_pages/soundcloud.png';
import tumblr from 'assets/images/social_pages/tumblr.png';
import twitter from 'assets/images/social_pages/twitter.png';
import vimeo from 'assets/images/social_pages/vimeo.png';
import wordpress from 'assets/images/social_pages/wordpress.png';
import yelp from 'assets/images/social_pages/yelp.png';
import youtube from 'assets/images/social_pages/youtube.png';
import mywave from 'assets/images/social_pages/mywave.png';
import clientportal from 'assets/images/social_pages/clientportal.png';
import mysgi from 'assets/images/social_pages/mysgi.png';
import harboriQuote from 'assets/images/social_pages/harboria_quote.png';
import chatWithText from 'assets/images/social_pages/chat_with_text.png';
import requestCert from 'assets/images/social_pages/request_cert.png';
import googleReview from 'assets/images/social_pages/google_review.png';
import harboriaPolicy from 'assets/images/social_pages/harboria_policy.png';
import clientLogin from 'assets/images/social_pages/client_login.png';
import support from 'assets/images/social_pages/support.png';
import defaultImage from 'assets/images/social_pages/default.png';
import calculator from 'assets/images/social_pages/calculator.png';
import grow from 'assets/images/social_pages/grow.png';
import finance from 'assets/images/social_pages/finance.png';
import requestAutoId from 'assets/images/social_pages/request_auto_id.png';
import harboriaPolicy2 from 'assets/images/social_pages/harboria_policy_2.png';
import truckIcon from 'assets/images/social_pages/truck_icon.png';
import starbucks from 'assets/images/social_pages/starbucks.png';
import scheduleMtg from 'assets/images/social_pages/schedule_mtg.png';
import scheduleCall from 'assets/images/social_pages/schedule_call.png';
import referral from 'assets/images/social_pages/referral.png';
import referAFriend from 'assets/images/social_pages/refer_a_friend.png';
import partners from 'assets/images/social_pages/partners.png';
import healthcare from 'assets/images/social_pages/healthcare.png';
import health from 'assets/images/social_pages/health.png';
import faq from 'assets/images/social_pages/faq.png';
import fandango from 'assets/images/social_pages/fandango.png';
import scheduler from 'assets/images/social_pages/scheduler.png';
import business from 'assets/images/social_pages/business.png';
import amazonShoping from 'assets/images/social_pages/amazon_shoping.png';
import access360 from 'assets/images/social_pages/360_access.png';
import calculatorGreen from 'assets/images/social_pages/calculator_green.png';
import claimsFaq from 'assets/images/social_pages/claims_faq.png';
import lifeQuote from 'assets/images/social_pages/life_quote.png';
import mackoulLife from 'assets/images/social_pages/mackoul_life.png';
import policyReview from 'assets/images/social_pages/policy_review.png';
import shareFile from 'assets/images/social_pages/share_file.png';
import farmAgri from 'assets/images/social_pages/farm_agri.png';
import bizQuote from 'assets/images/social_pages/biz_quote.png';
import busQuote from 'assets/images/social_pages/bus_quote.png';
import loansSmall from 'assets/images/social_pages/loans_small.png';
import homeLoans from 'assets/images/social_pages/home_loans.png';
import boat from 'assets/images/social_pages/boat.png';
import marine from 'assets/images/social_pages/marine.png';
import fire from 'assets/images/social_pages/fire.png';
import ticToc from 'assets/images/social_pages/tic_toc.png';
import chatBlue from 'assets/images/social_pages/chat_blue.png';
import whatsapp from 'assets/images/social_pages/whatsapp.png';
import liability from 'assets/images/social_pages/liability.png';
import property from 'assets/images/social_pages/property.png';
import clientLoginPurple from 'assets/images/social_pages/client_login_purple.png';
import clientLoginGreen from 'assets/images/social_pages/client_login_green.png';
import clientLoginBlue from 'assets/images/social_pages/client_login_blue.png';
import clientLoginTeal from 'assets/images/social_pages/client_login_teal.png';
import clientLoginMaroon from 'assets/images/social_pages/client_login_maroon.png';
import homeQuote from 'assets/images/social_pages/home_quote.png';
import rmvImage from 'assets/images/social_pages/rmv.png';
import quotesImage from 'assets/images/social_pages/quotes.png';
import oshaImage from 'assets/images/social_pages/osha.png';
import cckComplianceCheckEmerge from 'assets/images/social_pages/cck_compliance_check_emerge.png';
import asAutomateSafetyEmerge from 'assets/images/social_pages/as_automate_safety_emerge.png';
import newsletterImage from 'assets/images/social_pages/newsletter.jpeg';
import businessQuote from 'assets/images/social_pages/business_quote.png';
import referAFriendColor from 'assets/images/social_pages/refer_a_friend_color.jpeg';
import googleReviewColorIcon from 'assets/images/social_pages/google_review_color_icon.png';
import scheduleCallIcon from 'assets/images/social_pages/schedule_call_icon.png';
import petQuote from 'assets/images/social_pages/pet_quote.png';
import homeQuoteIcon from 'assets/images/social_pages/home_quote_icon.png';
import lifeQuoteIcon from 'assets/images/social_pages/life_quote_icon.png';
import jewelryQuote from 'assets/images/social_pages/jewelry_quote.png';
import floodQuote from 'assets/images/social_pages/flood_quote.png';
import AutoQuote from 'assets/images/social_pages/AutoQuote.png';
import epayIcon from 'assets/images/social_pages/epay_icon.png';
import premiumFinanceGeneric from 'assets/images/social_pages/premium_finance_generic.png';
import coiGreenPeopleTrk from 'assets/images/social_pages/coi_green_people_trk.png';
import coiGreenWithLines from 'assets/images/social_pages/coi_green_with_lines.png';
import coiBlueIcon from 'assets/images/social_pages/coi_blue_icon.png';
import certRequestBlue from 'assets/images/social_pages/cert_request_blue.png';
import certRequestShetetgreen from 'assets/images/social_pages/cert_request_shetetgreen.png';
import instantAutoQuoteBlue from 'assets/images/social_pages/instant_auto_quote_blue.png';
import autoQuoteIconBlue from 'assets/images/social_pages/auto_quote_icon_blue.png';
import tsPinkPreferLink from 'assets/images/social_pages/timeless_1445_pink_prefer_link.png';
import tsBlkPreferLink from 'assets/images/social_pages/timeless_blk_prefer_link.png';
import tsOrangePreferLink from 'assets/images/social_pages/timeless_orange_prefer_link.png';
import tsPurplePreferLink from 'assets/images/social_pages/timeless_1445_purple_prefer_link.png';
import tsYellowPreferLink from 'assets/images/social_pages/timeless_1445_yellow_prefer_link.png';
import tsSolPreferLink from 'assets/images/social_pages/timeless_sol_1445_blk_preferred_link.png';
import tsOrgPreferLink from 'assets/images/social_pages/timeless_sol_1445_org_preferred_link.png';
import tsPnkPreferLink from 'assets/images/social_pages/timeless_sol_1445_pnk_preferredlink.png';
import tsPrplPreferLink from 'assets/images/social_pages/timeless_sol_1445_prpl_preferred_link.png';
import tsYelPreferLink from 'assets/images/social_pages/timeless_sol_1445_yel_preferred_link.png';
import MerchPreferLink from 'assets/images/social_pages/merch_preferred_link.png';
import HomeWarrantyPreferLink from 'assets/images/social_pages/home_warranty_preferred_link.png';
import InsurLinkPreferLink from 'assets/images/social_pages/insur_link_preferred_link.png';
import InsurLinkPreferLinkV2 from 'assets/images/social_pages/insurLink_Stk_v2_prefer_link.png';
import YoungBloodCalendarLink from 'assets/images/social_pages/youngblood_calendar_link.png';
import YoungBloodCalendarLinkV2 from 'assets/images/social_pages/youngblood_calendar_link_v2.png';
import YoungBloodCalendarLinkV3 from 'assets/images/social_pages/youngblood_calendar_link_v3.png';
import Drs from 'assets/images/social_pages/drs.png';
import DrcClientLogin from 'assets/images/social_pages/drc_client_login.png';
import HomeAutoBlue from 'assets/images/social_pages/home_auto_blue.png';
import HomeAutoDarkV2 from 'assets/images/social_pages/home_auto_dark_v2.png';
import HomeAutoDark from 'assets/images/social_pages/home_auto_dark.png';
import HomeAutoGreenV2 from 'assets/images/social_pages/home_auto_green_v2.png';
import HomeAutoGreen from 'assets/images/social_pages/home_auto_green.png';
import HomeAutoIndigo from 'assets/images/social_pages/home_auto_indigo.png';
import HomeAutoOrange from 'assets/images/social_pages/home_auto_orange.png';
import HomeAutoRed from 'assets/images/social_pages/home_auto_red.png';
import HomeAutoWhite from 'assets/images/social_pages/home_auto_white.png';
import Lpl from 'assets/images/social_pages/lpl.png';
import MoneyGuide from 'assets/images/social_pages/money_guide.png';
import nextDoor from 'assets/images/social_pages/nextdoor.png';

export const ICON_GROUPS = {
  quote_lines_info: 'Quote / Lines Info',
  policy_related: 'Policy Related',
  life: 'Life / Health',
  finance: 'Finance / Bill Pay / Loans',
  social: 'Social',
  misc: 'Misc',
  color_icons: 'Color Icons',
};

export const ICONS = {
  quick_tips: {
    regexp: /mackoul\.com\/blog\/category\/quick-tips/,
    hidden: true,
    src: quickTips,
    groups: [],
  },
  video: { regexp: /video/, hidden: false, src: video, groups: [ICON_GROUPS.social] },
  behance: { regexp: /behance\.net/, hidden: true, src: behance, groups: [] },
  paypal: { regexp: /paypal\.com/, hidden: false, src: paypal, groups: [ICON_GROUPS.finance] },
  e_pay: { regexp: /pay.*\.com|payment/, hidden: false, src: ePay, groups: [ICON_GROUPS.finance] },
  blogger: { regexp: /blogger\.com/, hidden: true, src: blogger, groups: [] },
  blog: { regexp: /blog|newsletter/, hidden: false, src: blog, groups: [ICON_GROUPS.misc] },
  deviantart: { regexp: /deviantart\.com/, hidden: true, src: deviantart, groups: [] },
  dribbble: { regexp: /dribbble\.com/, hidden: true, src: dribbble, groups: [] },
  envato: { regexp: /envato\.com/, hidden: true, src: envato, groups: [] },
  evernote: { regexp: /evernote\.com/, hidden: true, src: evernote, groups: [] },
  fb_review: { regexp: /facebook\.com\/.*\/reviews(\/|$)/, hidden: false, src: fbReview, groups: [ICON_GROUPS.social] },
  facebook: {
    regexp: /facebook\.com(?!.*\/reviews(\/|$)).*$/,
    hidden: false,
    src: facebook,
    groups: [ICON_GROUPS.social],
  },
  flickr: { regexp: /flickr\.com/, hidden: true, src: flickr, groups: [] },
  googleplus: { regexp: /plus\.google\.com/, hidden: false, src: googleplus, groups: [ICON_GROUPS.social] },
  instagram: { regexp: /instagram\.com/, hidden: false, src: instagram, groups: [ICON_GROUPS.social] },
  linkedin: { regexp: /linkedin\.com/, hidden: false, src: linkedin, groups: [ICON_GROUPS.social] },
  messenger: { regexp: /messenger\.com|m\.me/, hidden: false, src: messenger, groups: [ICON_GROUPS.social] },
  pinterest: { regexp: /pinterest\.com/, hidden: false, src: pinterest, groups: [ICON_GROUPS.social] },
  rss: { regexp: /\/feed|\/rss/, hidden: false, src: rss, groups: [ICON_GROUPS.social] },
  skype: { regexp: /skype\.com/, hidden: false, src: skype, groups: [ICON_GROUPS.social] },
  soundcloud: { regexp: /soundcloud\.com/, hidden: true, src: soundcloud, groups: [] },
  tumblr: { regexp: /tumblr\.com/, hidden: true, src: tumblr, groups: [] },
  twitter: { regexp: /twitter\.com/, hidden: false, src: twitter, groups: [ICON_GROUPS.social] },
  vimeo: { regexp: /vimeo\.com/, hidden: false, src: vimeo, groups: [ICON_GROUPS.social] },
  wordpress: { regexp: /wordpress\.org/, hidden: true, src: wordpress, groups: [] },
  yelp: { regexp: /yelp\.\D{2,3}[/,\\]/, hidden: false, src: yelp, groups: [ICON_GROUPS.social] },
  youtube: { regexp: /youtube\.com|youtu\.be/, hidden: false, src: youtube, groups: [ICON_GROUPS.social] },
  support: { regexp: /portal\.csr24\.com/, hidden: false, src: support, groups: [ICON_GROUPS.policy_related] },
  mywave: { regexp: /mywaveportal\.com/, hidden: false, src: mywave, groups: [ICON_GROUPS.life] },
  clientportal: {
    regexp: /vertafore\.com|client.*portal/,
    hidden: false,
    src: clientportal,
    groups: [ICON_GROUPS.policy_related, ICON_GROUPS.misc],
  },
  mysgi: { regexp: /ezplates\.ca|mysgi/, hidden: false, src: mysgi, groups: [ICON_GROUPS.misc] },
  harboria_quote: { regexp: /quote/, hidden: false, src: harboriQuote, groups: [ICON_GROUPS.quote_lines_info] },
  chat_with_text: { regexp: /chat/, hidden: false, src: chatWithText, groups: [ICON_GROUPS.social] },
  request_cert: {
    regexp: /cert.*request|request.*cert|certrequest/,
    hidden: false,
    src: requestCert,
    groups: [ICON_GROUPS.policy_related],
  },
  google_review: {
    regexp: /google.*search.*writereview|google.*search.*review|google.*search.*placeid|search.*google.*writereview|search.*google.*review|search.*google.*placeid/,
    hidden: false,
    src: googleReview,
    groups: [ICON_GROUPS.social],
  },
  harboria_policy: {
    regexp: /polic.*chang|request.*chang.*service|service.*request.*chang|service.*chang.*request|polic.*updat.*service|service.*polic.*updat|request.*chang.*support|support.*request.*chang|support.*chang.*request|polic.*updat.*support|support.*polic.*updat/,
    hidden: false,
    src: harboriaPolicy,
    groups: [ICON_GROUPS.policy_related],
  },
  client_login: {
    regexp: /account|login/,
    hidden: false,
    src: clientLogin,
    groups: [ICON_GROUPS.policy_related, ICON_GROUPS.misc],
  },
  default: { regexp: /.*/, hidden: true, src: defaultImage, groups: [] },
  calculator: { hidden: false, src: calculator, groups: [ICON_GROUPS.finance] },
  grow: { hidden: false, src: grow, groups: [ICON_GROUPS.quote_lines_info] },
  finance: { hidden: false, src: finance, groups: [ICON_GROUPS.quote_lines_info, ICON_GROUPS.finance] },
  request_auto_id: { hidden: false, src: requestAutoId, groups: [ICON_GROUPS.policy_related] },
  harboria_policy_2: { hidden: false, src: harboriaPolicy2, groups: [ICON_GROUPS.policy_related] },
  truck_icon: { hidden: false, src: truckIcon, groups: [ICON_GROUPS.quote_lines_info] },
  starbucks: { hidden: false, src: starbucks, groups: [ICON_GROUPS.misc] },
  schedule_mtg: { hidden: false, src: scheduleMtg, groups: [ICON_GROUPS.misc] },
  schedule_call: { hidden: false, src: scheduleCall, groups: [ICON_GROUPS.misc] },
  referral: { hidden: false, src: referral, groups: [ICON_GROUPS.misc] },
  refer_a_friend: { hidden: false, src: referAFriend, groups: [ICON_GROUPS.misc] },
  partners: { hidden: false, src: partners, groups: [ICON_GROUPS.misc] },
  call_appt: { hidden: false, src: callAppt, groups: [ICON_GROUPS.misc] },
  free_gift_two: { hidden: false, src: freeGiftTwo, groups: [ICON_GROUPS.misc] },
  healthcare: { hidden: false, src: healthcare, groups: [ICON_GROUPS.life] },
  health: { hidden: false, src: health, groups: [ICON_GROUPS.life] },
  faq: { hidden: false, src: faq, groups: [ICON_GROUPS.misc] },
  fandango: { hidden: false, src: fandango, groups: [ICON_GROUPS.misc] },
  scheduler: { hidden: false, src: scheduler, groups: [ICON_GROUPS.misc] },
  service: { hidden: false, src: service, groups: [ICON_GROUPS.misc] },
  business: { hidden: false, src: business, groups: [ICON_GROUPS.quote_lines_info] },
  amazon_shoping: { hidden: false, src: amazonShoping, groups: [ICON_GROUPS.misc] },
  '360_access': { hidden: false, src: access360, groups: [ICON_GROUPS.misc] },
  calculator_green: { hidden: false, src: calculatorGreen, groups: [ICON_GROUPS.finance] },
  claims_faq: { hidden: false, src: claimsFaq, groups: [ICON_GROUPS.misc] },
  life_quote: { hidden: false, src: lifeQuote, groups: [ICON_GROUPS.quote_lines_info, ICON_GROUPS.life] },
  mackoul_life: { hidden: false, src: mackoulLife, groups: [ICON_GROUPS.quote_lines_info, ICON_GROUPS.life] },
  policy_review: { hidden: false, src: policyReview, groups: [ICON_GROUPS.policy_related] },
  farm_agri: { hidden: false, src: farmAgri, groups: [ICON_GROUPS.quote_lines_info] },
  share_file: { hidden: false, src: shareFile, groups: [ICON_GROUPS.policy_related, ICON_GROUPS.misc] },
  agri_tractor: { hidden: false, src: agriTractor, groups: [ICON_GROUPS.quote_lines_info] },
  cyber_ins_icon: { hidden: false, src: cyberInsIcon, groups: [ICON_GROUPS.quote_lines_info] },
  cyber_ins_v2: { hidden: false, src: cyberInsV2, groups: [ICON_GROUPS.quote_lines_info] },
  biz_quote: { hidden: false, src: bizQuote, groups: [ICON_GROUPS.quote_lines_info] },
  bus_quote: { hidden: false, src: busQuote, groups: [ICON_GROUPS.quote_lines_info] },
  loans_small: { hidden: false, src: loansSmall, groups: [ICON_GROUPS.finance] },
  home_loans: { hidden: false, src: homeLoans, groups: [ICON_GROUPS.quote_lines_info, ICON_GROUPS.finance] },
  marine: { hidden: false, src: marine, groups: [ICON_GROUPS.quote_lines_info] },
  boat: { hidden: false, src: boat, groups: [ICON_GROUPS.quote_lines_info] },
  fire: { hidden: false, src: fire, groups: [ICON_GROUPS.quote_lines_info] },
  tic_toc: { hidden: false, src: ticToc, groups: [ICON_GROUPS.social] },
  chat_blue: { hidden: false, src: chatBlue, groups: [ICON_GROUPS.social] },
  whatsapp: { hidden: false, src: whatsapp, groups: [ICON_GROUPS.social] },
  twitter_x: { hidden: false, src: twitterX, groups: [ICON_GROUPS.social] },
  vcheck: { hidden: false, src: vcheck, groups: [ICON_GROUPS.finance] },
  auto_quote: { hidden: false, src: autoQuote, groups: [ICON_GROUPS.quote_lines_info] },
  flood: { hidden: false, src: flood, groups: [ICON_GROUPS.quote_lines_info] },
  instant_life_coverege: { hidden: false, src: instantLifeCoverege, groups: [ICON_GROUPS.quote_lines_info] },
  building: { hidden: false, src: building, groups: [ICON_GROUPS.quote_lines_info] },
  instant_auto_quote: { hidden: false, src: instantAutoQuote, groups: [ICON_GROUPS.quote_lines_info] },
  loans: { hidden: false, src: loans, groups: [ICON_GROUPS.finance] },
  business_payment: { hidden: false, src: businessPayment, groups: [ICON_GROUPS.finance] },
  instant_quote: { hidden: false, src: instantQuote, groups: [ICON_GROUPS.quote_lines_info] },
  college_funding: { hidden: false, src: collegeFunding, groups: [ICON_GROUPS.finance] },
  no_debt: { hidden: false, src: noDebt, groups: [ICON_GROUPS.finance] },
  instant_home_quote: { hidden: false, src: instantHomeQuote, groups: [ICON_GROUPS.quote_lines_info] },
  pets: { hidden: false, src: pets, groups: [ICON_GROUPS.quote_lines_info, ICON_GROUPS.finance] },
  instant_auto_quote_forest: { hidden: false, src: instantAutoQuoteForst, groups: [ICON_GROUPS.quote_lines_info] },
  auto_quote_forest: { hidden: false, src: autoQuoteForest, groups: [ICON_GROUPS.quote_lines_info] },
  epay: { hidden: false, src: epay, groups: [ICON_GROUPS.finance] },
  imperial_pfs: { hidden: false, src: imperialPfs, groups: [ICON_GROUPS.finance] },
  simplyeasier: { hidden: false, src: simplyeasier, groups: [ICON_GROUPS.finance] },
  property: { hidden: false, src: property, groups: [ICON_GROUPS.quote_lines_info] },
  liability: { hidden: false, src: liability, groups: [ICON_GROUPS.quote_lines_info] },
  scheduler_red: { hidden: false, src: schedulerRed, groups: [ICON_GROUPS.misc] },
  scheduler_blue: { hidden: false, src: schedulerBlue, groups: [ICON_GROUPS.misc] },
  scheduler_green: { hidden: false, src: schedulerGreen, groups: [ICON_GROUPS.misc] },
  scheduler_yellow: { hidden: false, src: schedulerYellow, groups: [ICON_GROUPS.misc] },
  scheduler_sienna: { hidden: false, src: schedulerSienna, groups: [ICON_GROUPS.misc] },
  ag_workers_portal: { hidden: false, src: agWorkersPortal, groups: [ICON_GROUPS.misc] },
  pa_tags: { hidden: false, src: paTags, groups: [ICON_GROUPS.misc] },
  superior_cu: { hidden: false, src: superiorCu, groups: [ICON_GROUPS.finance] },
  client_login_purple: { hidden: false, src: clientLoginPurple, groups: [ICON_GROUPS.policy_related] },
  client_login_green: { hidden: false, src: clientLoginGreen, groups: [ICON_GROUPS.policy_related] },
  client_login_blue: { hidden: false, src: clientLoginBlue, groups: [ICON_GROUPS.policy_related] },
  client_login_teal: { hidden: false, src: clientLoginTeal, groups: [ICON_GROUPS.policy_related] },
  client_login_maroon: { hidden: false, src: clientLoginMaroon, groups: [ICON_GROUPS.policy_related] },
  home_quote: { hidden: false, src: homeQuote, groups: [ICON_GROUPS.policy_related] },
  rmv: { hidden: false, src: rmvImage, groups: [ICON_GROUPS.misc] },
  quotes: { hidden: false, src: quotesImage, groups: [ICON_GROUPS.policy_related] },
  osha: { hidden: false, src: oshaImage, groups: [ICON_GROUPS.misc] },
  cck: { hidden: false, src: cckComplianceCheckEmerge, groups: [ICON_GROUPS.misc] },
  as_automate_safety: { hidden: false, src: asAutomateSafetyEmerge, groups: [ICON_GROUPS.misc] },
  newsletter: { hidden: false, src: newsletterImage, groups: [ICON_GROUPS.color_icons] },
  business_quote: { hidden: false, src: businessQuote, groups: [ICON_GROUPS.color_icons] },
  refer_a_friend_color: { hidden: false, src: referAFriendColor, groups: [ICON_GROUPS.color_icons] },
  google_review_color_icon: { hidden: false, src: googleReviewColorIcon, groups: [ICON_GROUPS.color_icons] },
  schedule_call_icon: { hidden: false, src: scheduleCallIcon, groups: [ICON_GROUPS.color_icons] },
  pet_quote: { hidden: false, src: petQuote, groups: [ICON_GROUPS.color_icons] },
  home_quote_icon: { hidden: false, src: homeQuoteIcon, groups: [ICON_GROUPS.color_icons] },
  life_quote_icon: { hidden: false, src: lifeQuoteIcon, groups: [ICON_GROUPS.color_icons] },
  jewelry_quote: { hidden: false, src: jewelryQuote, groups: [ICON_GROUPS.color_icons] },
  flood_quote: { hidden: false, src: floodQuote, groups: [ICON_GROUPS.color_icons] },
  auto_quote_icon: { hidden: false, src: AutoQuote, groups: [ICON_GROUPS.color_icons] },
  epay_icon: { hidden: false, src: epayIcon, groups: [ICON_GROUPS.finance] },
  premium_finance_generic: { hidden: false, src: premiumFinanceGeneric, groups: [ICON_GROUPS.finance] },
  goto_premium_finance: { hidden: false, src: gotoPremiumFinance, groups: [ICON_GROUPS.finance] },
  coi_green_people_trk: { hidden: false, src: coiGreenPeopleTrk, groups: [ICON_GROUPS.policy_related] },
  coi_green_with_lines: { hidden: false, src: coiGreenWithLines, groups: [ICON_GROUPS.policy_related] },
  coi_blue_icon: { hidden: false, src: coiBlueIcon, groups: [ICON_GROUPS.policy_related] },
  cert_request_blue: { hidden: false, src: certRequestBlue, groups: [ICON_GROUPS.policy_related] },
  cert_request_shetetgreen: { hidden: false, src: certRequestShetetgreen, groups: [ICON_GROUPS.policy_related] },
  instant_auto_quote_blue: { hidden: false, src: instantAutoQuoteBlue, groups: [ICON_GROUPS.quote_lines_info] },
  auto_quote_icon_blue: { hidden: false, src: autoQuoteIconBlue, groups: [ICON_GROUPS.quote_lines_info] },
  timeless_blk_prefer_link: { hidden: false, src: tsBlkPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_1445_pink_prefer_link: { hidden: false, src: tsPinkPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_1445_purple_prefer_link: { hidden: false, src: tsPurplePreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_1445_yellow_prefer_link: { hidden: false, src: tsYellowPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_sol_1445_blk_preferred_link: { hidden: false, src: tsSolPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_sol_1445_org_preferred_link: { hidden: false, src: tsOrgPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_sol_1445_pnk_preferredlink: { hidden: false, src: tsPnkPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_sol_1445_prpl_preferred_link: { hidden: false, src: tsPrplPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_sol_1445_yel_preferred_link: { hidden: false, src: tsYelPreferLink, groups: [ICON_GROUPS.color_icons] },
  timeless_orange_prefer_link: { hidden: false, src: tsOrangePreferLink, groups: [ICON_GROUPS.color_icons] },
  merch_preferred_link: { hidden: false, src: MerchPreferLink, groups: [ICON_GROUPS.color_icons] },
  home_warranty_preferred_link: { hidden: false, src: HomeWarrantyPreferLink, groups: [ICON_GROUPS.color_icons] },
  insur_link_preferred_link: { hidden: false, src: InsurLinkPreferLink, groups: [ICON_GROUPS.color_icons] },
  insurLink_Stk_v2_prefer_link: { hidden: false, src: InsurLinkPreferLinkV2, groups: [ICON_GROUPS.color_icons] },
  youngblood_calendar_link: { hidden: false, src: YoungBloodCalendarLink, groups: [ICON_GROUPS.misc] },
  youngblood_calendar_link_v2: { hidden: false, src: YoungBloodCalendarLinkV2, groups: [ICON_GROUPS.misc] },
  youngblood_calendar_link_v3: { hidden: false, src: YoungBloodCalendarLinkV3, groups: [ICON_GROUPS.misc] },
  drc_client_login: { hidden: false, src: DrcClientLogin, groups: [ICON_GROUPS.misc] },
  drs: { hidden: false, src: Drs, groups: [ICON_GROUPS.misc] },
  home_auto_blue: { hidden: false, src: HomeAutoBlue, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_dark_v2: { hidden: false, src: HomeAutoDarkV2, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_dark: { hidden: false, src: HomeAutoDark, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_green_v2: { hidden: false, src: HomeAutoGreenV2, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_green: { hidden: false, src: HomeAutoGreen, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_indigo: { hidden: false, src: HomeAutoIndigo, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_orange: { hidden: false, src: HomeAutoOrange, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_red: { hidden: false, src: HomeAutoRed, groups: [ICON_GROUPS.quote_lines_info] },
  home_auto_white: { hidden: false, src: HomeAutoWhite, groups: [ICON_GROUPS.quote_lines_info] },
  lpl: { hidden: false, src: Lpl, groups: [ICON_GROUPS.finance] },
  money_guide: { hidden: false, src: MoneyGuide, groups: [ICON_GROUPS.finance] },
  nextdoor: { hidden: false, src: nextDoor, groups: [ICON_GROUPS.social] },
};
